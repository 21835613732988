import gsap from "gsap"
import SplitText from "./lib/SplitText"

const title = document.querySelector("h1")
const logo = document.querySelector(".logo")
const paragraph = document.querySelector("p")

const interestTitle = document.querySelector(".interest-title")
const interestList = document.querySelectorAll(".interest-list li")

const serviceTitle = document.querySelector(".service-title")
const serviceList = document.querySelectorAll(".service-list li")

const partnerTitle = document.querySelector(".partner-title")
const partnerList = document.querySelectorAll(".partners-wrapper li a")

const contactLink = document.querySelector(".contact-link")
const contactParagraph = document.querySelector(".contact-paragraph")
const arrow = document.querySelector(".arrow")

const splittedTitle = new SplitText(title, { type: "chars" })
const splittedParagraph = new SplitText(paragraph, { type: "lines" })

const splittedInterestTitle = new SplitText(interestTitle, { type: "chars" })
const splittedInterestList = new SplitText(interestList, { type: "lines" })

const splittedServiceTitle = new SplitText(serviceTitle, { type: "chars" })
const splittedServiceList = new SplitText(serviceList, { type: "lines" })

const splittedPartnerTitle = new SplitText(partnerTitle, { type: "chars" })
const splittedPartnerList = new SplitText(partnerList, { type: "chars" })

const splittedContactTitle = new SplitText(contactLink, { type: "chars" })
const splittedContactParagraph = new SplitText(contactParagraph, {
    type: "chars",
})

var options = {
    threshold: 1.0,
}

const triggerMain = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            gsap.to([logo, splittedTitle.chars, splittedParagraph.lines], {
                y: 0,
                opacity: 1,
                stagger: 0.05,
                ease: "expo.out",
                duration: 1,
            })
        }
    })
}

const triggerInterest = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            gsap.to([splittedInterestTitle.chars, splittedInterestList.lines], {
                y: 0,
                opacity: 1,
                stagger: 0.05,
                ease: "expo.out",
                duration: 1,
                delay: 0.25,
            })
        }
    })
}
const triggerService = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            gsap.to([splittedServiceTitle.chars, splittedServiceList.lines], {
                y: 0,
                opacity: 1,
                stagger: 0.05,
                ease: "expo.out",
                duration: 1,
            })
        }
    })
}

const triggerPartner = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            gsap.to([splittedPartnerTitle.chars, splittedPartnerList.chars], {
                y: 0,
                opacity: 1,
                stagger: 0.02,
                ease: "expo.out",
                duration: 1,
            })
        }
    })
}

const triggerContact = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            gsap.to([splittedContactTitle.chars, splittedContactParagraph.chars, arrow], {
                y: 0,
                opacity: 1,
                stagger: 0.02,
                ease: "expo.out",
                duration: 1,
            })
        }
    })
}

const mainObserver = new IntersectionObserver(triggerMain, options)
mainObserver.observe(document.getElementById("main"))

const interestObserver = new IntersectionObserver(triggerInterest, options)
interestObserver.observe(document.getElementById("interest"))

const serviceObserver = new IntersectionObserver(triggerService, options)
serviceObserver.observe(document.getElementById("service"))

const partnerObserver = new IntersectionObserver(triggerPartner, options)
partnerObserver.observe(document.getElementById("partner"))

const contactObserver = new IntersectionObserver(triggerContact, options)
contactObserver.observe(document.getElementById("contact"))

gsap.set(
    [
        logo,
        splittedTitle.chars,
        splittedParagraph.lines,
        splittedInterestTitle.chars,
        splittedInterestList.lines,
        splittedServiceTitle.chars,
        splittedServiceList.lines,
        splittedPartnerTitle.chars,
        splittedPartnerList.chars,
        splittedContactTitle.chars,
        splittedContactParagraph.chars,
        arrow,
    ],
    {
        opacity: 0,
        y: 30,
    }
)

const scroll = {
    current: 0,
    target: 0,
    ease: 0.1,
}

const wrapper = document.getElementById("wrapper")

document.addEventListener("wheel", (e) => {
    if (window.innerWidth > 640) {
        scroll.target += -e.deltaY
    }

    if (scroll.target > 0) {
        scroll.target = 0
    }

    if (scroll.target < -1000) {
        scroll.target = -1000
    }
})

const animate = () => {
    scroll.current += (scroll.target - scroll.current) * scroll.ease
    wrapper.style.transform = `translate3d(0, ${scroll.current}px, 0)`
    requestAnimationFrame(animate)
}

animate()
